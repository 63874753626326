import { Component, Provide, Vue, Watch } from 'vue-property-decorator';
import { OrderIdReq } from "@/interface/res/mine";
import { AcceptOrderApi, GetByIdOrderApi, GetPreTimeByOrderIdApi, RefundOrderApi,GetPaymentByZero } from "@/network/modules/mine";
import { PayReq } from "@/interface/res/common";
import { mapGetters } from "vuex";


@Component({
  components: {
  },
  computed: {
    ...mapGetters(["payData"])
  }
})
export default class Pay extends Vue {
  @Provide() id: number = -1
  @Provide() loading: boolean = false
  @Provide() detail: any = {}
  @Provide() expirationTime: any = {}
  @Provide() expirationTimer: any = null
  @Provide() paymentPrice: number = 0
  @Provide() paidPrice: number = 0
  @Provide() price: number = 0
  @Provide() bill: string = ''

  @Provide() infoShow: boolean = false
  @Provide() windowScreenWidth: boolean = false

  @Provide() preTimeByCart: string = '获取中....'

  @Provide() timeShow: boolean = false
  @Provide() latestPreTimeByCart: string = ''

  @Provide() progress: number = 0
  @Provide() progressTimer: any = null

  @Provide() remark: boolean = true

  @Provide() money: string = ''
  @Provide() moneyWarn: string = ''


  @Provide() payClick: boolean = false
  @Provide() payProgress: number = 0
  //[支付方式: 1-支付宝一次性 2-微信一次性 3-支付宝分笔 4-微信分笔]
  @Provide() payType: number = 0
  @Provide() aliPayBox: any = null
  @Provide() weixinPayShow: boolean = false
  @Provide() weixinPaySrc: any = null

  @Provide() schedulingShow: boolean = false
  @Provide() schedulingType: number = 1
  @Provide() schedulingTime: any = null
  @Provide() schedulingTimer: any = null
  @Provide() schedulingProgress: number = 0
  @Provide() schedulingConfirmBtn: boolean = false
  @Provide() schedulingConfirmTooBtn: boolean = false
  @Provide() schedulingClose: boolean = false
  @Provide() schedulingConfirmText: string = ''
  @Provide() schedulingConfirmLoading: boolean = false
  @Provide() schedulingConfirmTooText: string = ''
  @Provide() schedulingConfirmTooLoading: boolean = false

  @Provide() reimburseTime: any = {}
  @Provide() reimburseTimer: any = null

  @Watch('payData', { immediate: false, deep: false })
  private payDataChange(newVal: any): void {
    const _this: any = this;
    console.log(newVal, '--------------')
    if (_this.$base.isNull(newVal)) return
    console.log(_this.payType)
    console.log(_this.payData)
    _this.payClick = false;
    if (_this.payType == 4 || _this.payType == 2) {
      _this.weixinPayShow = false
      console.log('[ ------1· ] >')
    }
    else if (_this.payType == 1 || _this.payType == 3) {
      console.log('[ ------12 ] >')
      // console.log(_this.aliPayBox)
      // _this.aliPayBox.close()
    }
    console.log('[ ------13 ] >')
    if (newVal.orderStatus == 1) {
      console.log('[ ------1 ] >')
      _this.paidPrice = newVal.paidPrice
      // _this.money = _this.$base.subtr(_this.price, _this.paidPrice)
      _this.money="";
      // _this.paymentPrice = _this.$base.subtr(_this.price, _this.paidPrice)
      console.log(_this.detail);
      if (_this.detail.isDeposit == 1) {
        _this.GeNewtByIdOrderFn()
      } else {
        _this.paymentPrice = _this.$base.subtr(_this.detail.paymentPrice, _this.detail.paidPrice)
      }
      console.log('------',_this.paymentPrice);
      return;
    }

    if (newVal.orderStatus == 2 || newVal.orderStatus == 7 || newVal.orderStatus == 8 || newVal.orderStatus == 9) {
      console.log('[ ------2 ] >')
      console.log('[ detail.isDeposit ] >', _this.detail)
      if(_this.detail.isDeposit==1){
        if(_this.detail.depositStatus==1){
          _this.goRouter('orderDetail')
        }else if(_this.detail.depositStatus==0){
          _this.schedulingShow = true
        }
        // _this.GeNewtByIdOrderFn();
        
      }
      if(_this.detail.isDeposit==0){
        _this.schedulingShow = true
      }
      
      _this.schedulingType = newVal.orderStatus
      _this.schedulingTime = _this.payData.realSchedulingTime
      _this.preSchedulingTime = _this.payData.preSchedulingTime
      _this.schedulingProgressFn()
      return;
    }

  }

  @Watch('schedulingProgress', { immediate: false, deep: false })
  private iDataChange(newVal: number): void {
    const _this: any = this;
    if (newVal < 100) {
      _this.schedulingConfirmBtn =
        _this.schedulingConfirmTooBtn =
        _this.schedulingClose = false
      return
    }

    if (_this.schedulingType == 8) {
      _this.schedulingConfirmBtn =
        _this.schedulingConfirmTooBtn =
        _this.schedulingClose = true
      _this.schedulingConfirmText = '接受延时'
      _this.schedulingConfirmTooText = '立即发起退款'
      _this.reimburseCountDown(_this.payData.expirationTime)
      return;
    }

    if (_this.schedulingType == 2) {
      _this.schedulingConfirmBtn =
        _this.schedulingClose = true
      _this.schedulingConfirmTooBtn = false
      _this.schedulingConfirmText = '确定'
      return
    }

    if (_this.schedulingType == 9) {
      _this.schedulingConfirmBtn =
        _this.schedulingClose = true
      _this.schedulingConfirmTooBtn = false
      _this.schedulingConfirmText = '确定'
      return
    }
  }

  created() {
    const _this: any = this;
    window.scrollTo({ top: 0 })
    if (!_this.$base.isNull(_this.$route.query.i)) {
      _this.id = _this.$decryptBy(_this.$route.query.i)
    }
  }

  mounted() {
    const _this: any = this;
    _this.GetByIdOrderFn()
    _this.GetPreTimeByOrderIdFn()
    _this.windowScreenWidth = (document.body.style as any).zoom == 0.8 ? true : false;
    _this.windowScreenWidth = (document.body.style as any).zoom == 0.8 ? true : false;
  }

  confirmCallbackFn() {
    const _this: any = this;
    _this.schedulingShow = false;
    if (_this.schedulingType == 8) {
      _this.AcceptOrderFn()
      return
    }
    console.log('-------------1');
    _this.goRouter('orderDetail')
  }

  /**
   * @Author HS
   * @Date 2021/8/11 4:04 下午
   * @Description: 接受延时
   * @Params: null
   * @Return: null
  */
  async AcceptOrderFn() {
    const _this: any = this;
    _this.schedulingConfirmLoading = true
    let params: OrderIdReq = {
      orderId: _this.id
    }
    const res = await AcceptOrderApi(params)
    let a = setTimeout(() => { _this.schedulingConfirmLoading = false; clearTimeout(a) }, 500)
    const { code, msg, data } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }
    _this.$message({
      message: '已接受延时',
      type: 'success',
      duration: 1500,
      onClose: () => {
        console.log('-------------2');
        _this.goRouter('orderDetail')
      }
    });
  }

  /**
   * @Author HS
   * @Date 2021/8/11 4:00 下午
   * @Description: 立即退款
   * @Params: null
   * @Return: null
  */
  async RefundOrderFn() {
    const _this: any = this;
    _this.schedulingConfirmTooLoading = true
    let params: OrderIdReq = {
      orderId: _this.id
    }
    const res = await RefundOrderApi(params)
    let a = setTimeout(() => { _this.schedulingConfirmTooLoading = false; clearTimeout(a) }, 500)
    const { code, msg } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }
    _this.$message({
      message: '已退款',
      type: 'success',
      duration: 1500,
      onClose: () => {
        console.log('-------------3');
        _this.goRouter('orderDetail')
      }
    });
  }

  /**
   * @Author HS
   * @Date 2021/8/10 2:21 下午
   * @Description: 点击分笔支付
   * @Params: { number ：iType - true } [支付方式: 3-支付宝分笔 4-微信分笔]
   * @Return: null
  */
  clickMorePay(iType: number) {
    const _this: any = this;
    if (_this.$base.isNull(_this.money)) {
      _this.moneyWarn = '请输入金额'
      return
    }
    if (_this.$base.isNull(_this.moneyWarn)) {
      _this.PayGetPreTimeFn(iType)
    }
  }

  /**
   * @Author HS
   * @Date 2021/8/10 2:12 下午
   * @Description: 确定支付
   * @Params: null
   * @Return: null
  */
  surePay() {
    const _this: any = this;
    let paramsLink = process.env.VUE_APP_BASE_URL
    let params: PayReq = {
      orderId: this.id,
      payPrice: -1,
      payType: -1,
    }
    // 1-支付宝一次性
    if (_this.payType == 1) {
      params.payPrice = _this.paymentPrice
      params.payType = 0
      paramsLink += '/shop/pay/ali/pcPay?' + _this.$base.serializeStr(params)
      _this.aliPayBox = window.open(paramsLink, "_blank");
      _this.payClick = false
      return
    }
    // 3-支付宝分笔
    if (_this.payType == 3) {
      params.payPrice = _this.money
      params.payType = 1
      paramsLink += '/shop/pay/ali/pcPay?' + _this.$base.serializeStr(params)
      _this.aliPayBox = window.open(paramsLink, "_blank");
      _this.payClick = false
      return
    }
    // 2-微信一次性
    if (_this.payType == 2) {
      params.payPrice = _this.paymentPrice
      params.payType = 0
      paramsLink += '/shop/pay/wx/qrPay?' + _this.$base.serializeStr(params)
      _this.weixinPayShow = true
      // _this.windowScreenWidth = document.body.clientWidth < 1440 ? true : false;
      _this.windowScreenWidth = (document.body.style as any).zoom == 0.8 ? true : false;
      _this.weixinPaySrc = paramsLink
      _this.payClick = false
      return
    }
    // 4-微信分笔
    if (_this.payType == 4) {
      params.payPrice = _this.money
      params.payType = 1
      paramsLink += '/shop/pay/wx/qrPay?' + _this.$base.serializeStr(params)
      _this.weixinPayShow = true
      // _this.windowScreenWidth = document.body.clientWidth < 1440 ? true : false;
      _this.windowScreenWidth = (document.body.style as any).zoom == 0.8 ? true : false;
      _this.weixinPaySrc = paramsLink
      _this.payClick = false
      return
    }

  }


  /**
   * @Author HS
   * @Date 2021/8/10 4:37 下午
   * @Description: 点击支付查看排单时间
   * @Params: { number ：iType - true } [支付方式: 1-支付宝一次性 2-微信一次性 3-支付宝分笔 4-微信分笔]
   * @Return: null
  */
  async PayGetPreTimeFn(iType: number) {
    const _this: any = this;

    if(_this.detail.isDeposit==1){
       if(_this.detail.depositStatus==0){
        if (_this.preTimeByCart == '获取中....') {
          _this.$message.error('正在获取预发货时间，请稍等...');
          return
        }
      }
    }else{
      if (_this.preTimeByCart == '获取中....') {
        _this.$message.error('正在获取预发货时间，请稍等...');
        return
      }
    }
    
    if (_this.preTimeByCart == '获取失败') {
      _this.$message.error('系统异常，请稍后再试');
      return
    }

    _this.payProgress = 0
    _this.weixinPaySrc = ''
    _this.payClick = true
   
    let params: OrderIdReq = {
      orderId: _this.id
    }
    if(_this.paymentPrice==0){
      _this.progressPayFnx()
      const res = await GetPaymentByZero(params)
      const { code, data } = res
    }else{
      _this.progressPayFn()
      const res = await GetPreTimeByOrderIdApi(params)
      const { code, data } = res
      if (code != 200) {
        _this.progressPayFn("获取失败")
        _this.$message.error('系统异常，请稍后再试');
        return
      }
      _this.latestPreTimeByCart = data
      _this.progressPayFn(data)
    }

    setTimeout(() => {
      _this.payClick = false
    }, 1000);
   
    _this.payType = iType
   
   
    


  }

  /**
   * @Author HS
   * @Date 2021/8/10 11:25 上午
   * @Description: 获取排单发货时间
   * @Params: null
   * @Return: null
  */
  async GetPreTimeByOrderIdFn() {
    const _this: any = this;
    _this.progressFn()
    let params: OrderIdReq = {
      orderId: _this.id
    }
    const res = await GetPreTimeByOrderIdApi(params)
    const { code, data } = res
    if (code != 200) {
      _this.progressFn("获取失败")
      return
    }
    _this.progressFn(data)
  }


  /**
   * @Author HS
   * @Date 2021/8/10 11:07 上午
   * @Description: 产品详情
   * @Params: null
   * @Return: null
   */
   async GeNewtByIdOrderFn() {
    const _this: any = this;
    _this.loading = true;
    let params: OrderIdReq = {
      orderId: _this.id
    }
    const res = await GetByIdOrderApi(params)
    let a = setTimeout(() => { _this.loading = false; clearTimeout(a) }, 500)
    const { code, msg, data } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }
    _this.detail = data;

    if (_this.detail.depositStatus == 0) {
      _this.paymentPrice = _this.$base.toFixed(_this.$base.accAdd(_this.detail.paymentDepositPrice, -
        _this.detail.paidPrice));
    } else {
      _this.paymentPrice = _this.$base.toFixed(_this.$base.accAdd(_this.detail.paymentPrice, _this.detail
        .paidPrice));
    }
  }
  async GetByIdOrderFn() {
    const _this: any = this;
    _this.loading = true;
    let params: OrderIdReq = {
      orderId: _this.id
    }
    const res = await GetByIdOrderApi(params)
    let a = setTimeout(() => { _this.loading = false; clearTimeout(a) }, 500)
    const { code, msg, data } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }
    _this.detail = data;
    console.log(data);
    if (_this.detail.isDeposit==0&&_this.detail.orderStatus != 1) {
      _this.countDown(_this.detail.expirationTime)
    }
    if(_this.detail.isDeposit==1&&_this.detail.depositStatus==0&&_this.detail.orderStatus != 1){
      _this.countDown(_this.detail.expirationTime)
    }

    _this.price = data.paymentPrice
    _this.paymentPrice = _this.$base.subtr(data.paymentPrice, data.paidPrice)
    console.log( _this.paymentPrice)

    if (_this.detail.isDeposit == 1) {
      if (data.depositStatus == 0) {
        _this.paymentPrice = _this.$base.toFixed(_this.$base.accAdd(data.paymentDepositPrice, -
          data.paidPrice));
      } else {
        _this.paymentPrice = _this.$base.toFixed(_this.$base.accAdd(data.paymentPrice, -data
          .paidPrice));
      }
    } else {
      _this.paymentPrice = _this.$base.subtr(data.paymentPrice, data.paidPrice)
      // _this.paymentPrice = this.$base.accAdd(data.paymentPrice, -data.paidPrice);
    }

    // _this.money = _this.paymentPrice.toString()
    _this.paidPrice = data.paidPrice

    if (_this.$base.isNull(data.orderInvoice)) {
      _this.bill = '不需要'
    }
    else {
      if (data.orderInvoice.invoiceType == 0) {
        _this.bill = '个人普票 ' + data.orderInvoice.invoiceTitle
      }
      else if (data.orderInvoice.invoiceType == 1) {
        _this.bill = '企业普票 ' + data.orderInvoice.invoiceTitle
      }
      else if (data.orderInvoice.invoiceType == 2) {
        _this.bill = '企业专票 ' + data.orderInvoice.invoiceTitle
      }
    }

    if (data.orderStatus == 0 || data.orderStatus == 1) return
    if (data.orderStatus == 2 && data.isDeposit == 1) return
    console.log('-------------4');
    _this.goRouter('orderDetail')

  }

  /**
   * @Author HS
   * @Date 2021/8/10 6:12 下午
   * @Description: 关闭排单弹窗
   * @Params: null
   * @Return: null
  */
  schedulingHideFn() {
    const _this: any = this;
    _this.schedulingShow = false
  }

  /**
   * @Author HS
   * @Date 2021/8/10 11:52 上午
   * @Description: 分笔支付金额计算
   * @Params: null
   * @Return: null
  */
  blurCallbackFn() {
    const _this: any = this;
    if (_this.$base.isNull(_this.money)) {
      _this.moneyWarn = '请输入金额'
      return
    }
    let num = _this.$base.subtr(_this.paymentPrice, _this.money)
    if (num.indexOf('-') > -1) {
      _this.moneyWarn = '您输入的金额不能大于待付款金额'
      return
    }

    _this.moneyWarn = ''
  }

  /**
   * @Author HS
   * @Date 2021/8/10 11:07 上午
   * @Description: 排单中进度条
   * @Params: { string ： str - true } [发货时间]
   * @Return: null
   */
  schedulingProgressFn() {
    const _this: any = this;
    clearTimeout(_this.schedulingTimer)
    const aTimer = [50, 56, 62, 68, 74]
    const index = Math.floor(Math.random() * Math.floor(4));
    let timer = aTimer[index];
    _this.schedulingTimer = setTimeout(() => {
      _this.schedulingProgress++
      if (_this.schedulingProgress >= 100) {
        clearTimeout(_this.schedulingTimer)
        return
      }
      _this.schedulingProgressFn()
    }, timer)
  }
  progressPayFnx(str: string) {
    console.log(';;;;;;;');
    const _this: any = this;
    clearTimeout(_this.progressPayTimer)
    // const aTimer = [50,56,62,68,74]
    const aTimer = [5, 6, 7, 8, 9]
    const index = Math.floor(Math.random() * Math.floor(4));
    let timer = 40;
    if (_this.$base.isNull(str)) {
      timer = aTimer[index]
    }
    _this.progressPayTimer = setTimeout(() => {
      _this.payProgress++
      if (_this.payProgress >= 100) {
        if (str != _this.preTimeByCart) {
         
        }
        else {
          _this.surePay()
        }
        clearTimeout(_this.progressPayTimer)
        return
      }
      _this.progressPayFnx(str)
    }, timer)
  }

  /**
   * @Author HS
   * @Date 2021/8/10 11:07 上午
   * @Description: 支付进度条
   * @Params: { string ： str - true } [发货时间]
   * @Return: null
   */
  progressPayFn(str: string) {
    const _this: any = this;
    clearTimeout(_this.progressPayTimer)
    // const aTimer = [50,56,62,68,74]
    const aTimer = [5, 6, 7, 8, 9]
    const index = Math.floor(Math.random() * Math.floor(4));
    let timer = 40;
    if (_this.$base.isNull(str)) {
      timer = aTimer[index]
    }
    _this.progressPayTimer = setTimeout(() => {
      _this.payProgress++
      if (_this.payProgress >= 100) {
        if (str != _this.preTimeByCart) {
          _this.timeShow = true
        }
        else {
          _this.surePay()
        }
        clearTimeout(_this.progressPayTimer)
        return
      }
      _this.progressPayFn(str)
    }, timer)
  }

  /**
   * @Author HS
   * @Date 2021/8/10 11:07 上午
   * @Description: 进度条
   * @Params: { string ： str - true } [发货时间]
   * @Return: null
  */
  progressFn(str: string) {
    const _this: any = this;
    clearTimeout(_this.progressTimer)
    const aTimer = [300, 360, 420, 460, 500]
    const index = Math.floor(Math.random() * Math.floor(4));
    let timer = 40;
    if (_this.$base.isNull(str)) {
      timer = aTimer[index]
    }
    _this.progressTimer = setTimeout(() => {
      _this.progress++
      if (_this.progress >= 100) {
        _this.preTimeByCart = str
        clearTimeout(_this.progressTimer)
        return
      }
      _this.progressFn(str)
    }, timer)
  }


  /**
   * @Author HS
   * @Date 2021/8/6 2:36 下午
   * @Description: 付款时间倒计时
   * @Params: { number ： endTimeStamp - true } [时间戳]
   * @Return: null
   */
  reimburseCountDown(endTimeStamp: number) {
    const _this: any = this;
    _this.reimburseTimer = setTimeout(() => {
      let expirationTime = _this.$base.countDown(endTimeStamp)
      if (expirationTime.end) {
        clearTimeout(_this.reimburseTimer)
        _this.$message({
          message: '交易关闭',
          type: 'error',
          duration: 1500,
          onClose: () => {
            // _this.goRouter('orderDetail')
          }
        });
        return
      }
      _this.reimburseCountDown(endTimeStamp)
      _this.reimburseTime = expirationTime
    }, 1000)
  }

  /**
   * @Author HS
   * @Date 2021/8/6 2:36 下午
   * @Description: 付款时间倒计时
   * @Params: { number ： endTimeStamp - true } [时间戳]
   * @Return: null
   */
  countDown(endTimeStamp: number) {
    const _this: any = this;
    _this.expirationTimer = setTimeout(() => {
      let expirationTime = _this.$base.countDown(endTimeStamp)
      // console.log(expirationTime);
      if (expirationTime.end) {
        clearTimeout(_this.expirationTimer)
        _this.$message({
          message: '交易关闭',
          type: 'error',
          duration: 1500,
          onClose: () => {
            // _this.goRouter('orderDetail')
          }
        });
        return
      }
      _this.countDown(endTimeStamp)
      _this.expirationTime = expirationTime
    }, 1000)
  }

  /**
   * @Author HS
   * @Date 2021/7/7 2:07 下午
   * @Description: 页面跳转
   * @Params: { string ： iPath - true } [路径]
   * @Return: null
   */
  goRouter(iPath: string) {
    const _this: any = this;
    const link = _this.$base.routeLinkSplice(_this.$MineConfig[iPath].path, "i=" + _this.id.toString())
    _this.$base.goRouter(link)
  }

}
